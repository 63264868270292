import './bootstrap'
import '../css/app.css';


import CamerasPage from './pages/cameras';
import CameraPage from './pages/cameras/_camera';

const route = document.querySelector('body').dataset.route;

console.log(`Route: ${route}`);
 

if (route === 'public.cameras.index') {
    CamerasPage();
}

if (route === 'public.cameras.show') {
    CameraPage();
}
