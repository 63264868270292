<template>
    <v-mapa :items="[camera]" :center="latLng">
        <template v-slot:popup="{ item }">
            <h2 class="subtitle">{{ item.name }}</h2>
            <strong>Població: </strong>{{ item.city }}
            <br />
            <div class="columns has-text-centered">
                <div class="column is-12">
                    {{ camera.name }}
                    <figure class="image container is-24x24">
                        <img :src="camera.thumb" loading="lazy" />
                    </figure>
                </div>
            </div>
        </template>
    </v-mapa>
</template>
<script>
import { computed } from "vue";
import mapa from "./mapa.vue";

export default {
    name: "camera-map",
    components: {
        "v-mapa": mapa,
    },
    setup() {
        const app = document.getElementById("app");
        const { id, name, node } = JSON.parse(app.dataset.camera);
        const { lat, lon } = node;
        return {
            camera: {
                id,
                name,
                lat,
                lon,
            },
            latLng: computed(() => [
                Number.parseFloat(lat),
                Number.parseFloat(lon),
            ]),
        };
    },
};
</script>