<template>
    <div style="height: 80vh; width: 100%">
        <l-map
            :zoom="zoom"
            :center="center"
            :options="mapOptions"
            @update:center="centerUpdate"
            @update:zoom="zoomUpdate"
            style="z-index: 0"
        >
            <l-marker
                v-for="(item, key) in items"
                :key="key"
                :lat-lng="[item.lat, item.lon]"
                :icon="icon"
            >
                <l-popup>
                    <slot name="popup" :item="item">
                        {{ item }}
                    </slot>
                </l-popup>
            </l-marker>

            <l-tile-layer :url="url" :attribution="attribution" />
        </l-map>
    </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
} from "@vue-leaflet/vue-leaflet";

export default {
    name: "mapa",
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        LTooltip,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        center: {
            type: Array,
            default: () => [41.9020911, 1.8423021],
        },
    },
    data() {
        return {
            zoom: 9,
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            attribution:
                '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            currentZoom: 9,
            currentCenter: null,
            mapOptions: {
                zoomSnap: 0.5,
            },
            icon: L.icon({
                iconUrl: '/images/location-marker.svg',
                iconSize: [32, 37],
                iconAnchor: [16, 37],
            }),
        };
    },
    created() {
        this.currentCenter = this.center;
    },
    methods: {
        /** */
        zoomUpdate(zoom) {
            this.currentZoom = zoom;
        },
        /** */
        centerUpdate(center) {
            this.currentCenter = center;
        },
    },
};
</script>